import React from 'react';
import ClosedLunch from './closedlunch';
import ClosedBreakfast
 from './closedbreakfast';
 import ClosedDinner from './closeddinner';

export default function ClosedMenu(data) {
    console.log('interior of closed menu',data)
    return data.data.length > 0 ? (
        <div>
        <ClosedBreakfast data={data.data}/>
        <ClosedLunch data={data.data}/>
        {/* <ClosedDinner data={data.data}/> */}
        </div>
    ) : null
}