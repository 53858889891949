import React from 'react';

export default function Footer() {
    return (
        <>
        {/* <div className="footer">
            <div className="footer_image">
                <img src="./qr-code.png"/>
            </div>
        </div> */}
        <div className="footer_2">
        <div className="test_footer_image">
            <img src="./footer_png.png"/>
        </div>
        </div>
    </>
    )
}