import React from 'react';

export default function ClosedBreakfast(data) {
  return (
        <div>
            <h1>BREAKFAST</h1>
            <div className="breakfastclosedwrap">
            <div className="breakfastclosedcolumn">
            {data.data.filter(e=>e.menu==="breakfast").slice(0,2).map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_breakfast">{d.name}</div>
              <div className="item_description">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? '' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}
        </div>
        <div className="breakfastclosedcolumn">
            {data.data.filter(e=>e.menu==="breakfast").slice(2,4).map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_breakfast">{d.name}</div>
              <div className="item_description">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? '' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}
        </div>

        </div>
        </div>
    )
}