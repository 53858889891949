import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { io } from 'socket.io-client';
import ClosedMenu from './closedmenu';
import HOST_URL from './config';
import Footer from './Footer';

console.log('Current Host:', HOST_URL);
console.log('ABC', process.env.NODE_ENV)

const socket = io(HOST_URL);
function App() {

  const [data,setData] = useState([]);
  useEffect(()=>{
    const loadMenu = () => {

      axios.get(HOST_URL + `/menuitems/list${process.env.NODE_ENV === 'production' ? '' : '?menumode=lunch'}`).then(res=>{
        setData({list:res.data.list,menu:process.env.NODE_ENV === 'production' ? res.data.menu : 'lunch'})
        console.log('STATE',data)
      }).catch(error=>{
        console.log(error);
      })
      console.log('the socket',socket)
    }
    loadMenu();
    socket.on('reloadData',loadMenu);
    socket.on('message', (data) => {
      console.log('received message:' ,data)
    } )

    return () => {
      socket.off('reloadData',loadMenu)
    }
  },[])
  console.log('THE DATA',data.list)

return data.list ? data.menu == "breakfast" ? (
<div className="container">
    <div className="header">
      <img src="1.jpg" className="headerimage"/>
    </div>
    <div className="menu_container">
      <div className="pngtop">
      <img src="5.png"/>
      </div>
        {data.list.filter(m=>m.menu == "breakfast").map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name">{d.name}</div>
              <div className="item_description">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? 'soldout' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}
    </div>
    <div className="bottom_dots"></div>
        <Footer />
  </div>

  ) : data.menu == "lunch" ? (
    <div className="container">
    <div className="header">
      <img src="1.jpg" className="headerimage"/>
    </div>
    <div className="menu_container">
      <div className="pngtop">
      <img src="6.png"/>
      </div>
      <div className="lunch_container">
        <div className="lunch_left">
        <div className="lunch_header">
          <div className="lunch_header_top">MADE TO ORDER</div>
          <div className="lunch_header_bottom">ORDER AT THE REGISTER</div>
        </div>
        {data.list.filter(e=>e.menu2 == "madetoorder").slice(0,7).map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_lunch">{d.name}</div>
              <div className="item_description_lunch">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price_lunch">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? 'soldout' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}

        </div>
        <div className="lunch_right">
        {data.list.filter(e=>e.menu2 == "madetoorder").slice(7,12).map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_lunch">{d.name}</div>
              <div className="item_description_lunch">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price_lunch">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? 'soldout' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}

        <div className="lunch_header">
          <div className="lunch_header_top">WARM GRAB AND GO</div>
          <div className="lunch_header_bottom">GRAB BEFORE PAYING</div>
        </div>

        {data.list.filter(e=>e.menu2 == "grabandgo").map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_lunch">{d.name}</div>
              <div className="item_description_lunch">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price_lunch">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? 'soldout' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}


        </div>

      </div>
      <div className="add_ons">
          <div className="add_on">$1 Add On - Green Chile</div>
          <div className="add_on">Upon Request - BBQ Sauce or Chipotle Mayo</div>
        </div>
        <div className="hot_soup">
          <div className="hot_soup_title">HOT SOUP</div>
          <div className="hot_soup_items">
          {data.list.filter(e=>e.menu2 == "soup" && e.soldout == false).map(d=>{
            return (
              <div className="hsitem">
                  <div className="hsname">{d.name}</div>
                  <div className="hsprice">{d.price}</div>
              </div>

            )
          })}
          </div>
        </div>
        

    </div>
    <div className="bottom_dots"></div>

          <Footer/>
  </div>
  ) : data.menu == "dinner" ? (
    <div className="container">
    <div className="header">
      <img src="1.jpg" className="headerimage"/>
    </div>
    <div className="menu_container">
    <h1 style={{textAlign:'center'}}>dinner</h1>
    </div>
    <Footer/>
  </div>
  ) : data.menu == "closedmenu" ? (
    <div className="container">
    <div className="header">
      <img src="1.jpg" className="headerimage"/>
    </div>
    <div className="closed">CLOSED</div>
    <div className="menu_container">
    <ClosedMenu data={data.list}/>
    </div>
    <div className="bottom_dots"></div>

    <Footer />
  </div>
  ) : data.menu == "closedblank" ? (
    <div className="container">
      <div className="header">
        <img src="1.jpg" className="headerimage"/>
      </div>
      <div className="closed_container">
        <div className="closed">CLOSED</div>
      </div>
      <Footer />
  </div>
  ) : null : null;
}

export default App;
