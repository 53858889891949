import React from 'react';

export default function ClosedLunch(data) {
    return (
        <div>
            <h1>LUNCH</h1>
        <div className="lunch_container">
        <div className="closedlunchcolumn">
        {data.data.filter(e=>e.menu == "lunch").slice(0,5).map(d=>{
            console.log('getting made to order')
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_lunch">{d.name}</div>
              <div className="item_description_lunch">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price_lunch">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? '' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}

        </div>
        <div className="closedlunchcolumn">

        {data.data.filter(e=>e.menu == "lunch").slice(5,10).map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_lunch">{d.name}</div>
              <div className="item_description_lunch">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price_lunch">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? '' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}


        </div>
        <div className="closedlunchcolumn">

        {data.data.filter(e=>e.menu == "lunch").slice(10,15).map(d=>{
          return (
            <div className="menu_item" key={d._id}>

            <div className="menu_left">
              <div className="item_name_lunch">{d.name}</div>
              <div className="item_description_lunch">{d.description}</div>
            </div>
            <div className="menu_center"></div>
            <div className="menu_right">
              <div className="item_price_lunch">{d.price}</div>
            </div>
            <div className={`menu_soldout ${d.soldout ? '' : ''}`}>SOLD OUT</div>
          </div>
    
          )

        })}


        </div>

      </div>
      <div className="add_ons">
          <div className="add_on">$1 Add On - Green Chile</div>
          <div className="add_on">Upon Request - BBQ Sauce, Chipotle Sauce, or Mayo</div>
        </div>
        <div className="hot_soup">
          <div className="hot_soup_title">HOT SOUP</div>
          <div className="hot_soup_items">
          {data.data.filter(e=>e.menu2 == "soup" && e.soldout == false).map(d=>{
            return (
              <div className="hsitem">
                  <div className="hsname">{d.name}</div>
                  <div className="hsprice">{d.price}</div>
              </div>

            )
          })}

          </div>
        </div>
        
        </div>

    )
}